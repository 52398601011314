import { Draft } from '@reduxjs/toolkit'
import { DraftSectionItem } from '../../components/Section/Section.model'
import {
  DraftEntryResponseOption,
  DraftMatrixRow
} from '../../data/gql-gen/questionnaire/graphql'
import {
  DraftLogicClauseProposition,
  DraftQuestionnaire,
  DraftQuestionnaireEntry,
  EntrySettingValue,
  EntryType,
  TextEntryState,
  ValidationError
} from '../../data/model/questionnaire'
import { ResponseOptionType } from '../../data/model/shared'
import {
  MatrixRowsByQuestion,
  QuestionnaireState,
  ResponseOptionsByQuestion
} from './Questionnaire.slice'
import {
  validateForkEntry,
  validateMatrixEntry,
  validateQuestionEntry,
  validateTextCardEntry
} from './QuestionnaireValidation.utils'
import { SCROLL_ID_PREFIX } from './constants'

export const createDraftResponseOption = (
  position: number,
  value = ''
): DraftEntryResponseOption => {
  return {
    createdDate: new Date().toISOString(),
    sinceDate: new Date().toISOString(),
    pinned: false,
    exclusive: false,
    textEntryState: TextEntryState.TextEntryDisabled,
    qualification: null,
    responseOption: {
      responseOptionId: `responseOptionId-${position}`,
      rotype: ResponseOptionType.RoString,
      value,
      createdDate: '2022-06-20T14:57:31.832749Z',
      __typename: 'ResponseOption'
    },
    route: null,
    position,
    responseOptionLk: `responseOptionLk-${position}`,
    maskingRules: [],
    media: null,
    augmentations: [],
    marketIds: [],
    __typename: 'DraftEntryResponseOption'
  }
}

export const createDraftMatrixRow = (
  position: number,
  text = ''
): DraftMatrixRow => {
  const currentDate = new Date().toISOString()
  return {
    augmentations: [],
    marketIds: [],
    position,
    createdDate: currentDate,
    sinceDate: currentDate,
    questionLk: `questionLk-${position}`,
    question: {
      __typename: 'Question',
      questionId: `questionId-${position}`,
      text,
      createdDate: currentDate
    },
    maskingRules: [],
    pinned: false,
    questionMedia: null,
    forks: [],
    __typename: 'DraftMatrixRow'
  }
}

export const onScrollStop = (
  callback: () => unknown,
  scrollWrapperNode: HTMLElement | null
) => {
  let scrollingTimeoutId = -1
  const scrollHandler = () => {
    window.clearTimeout(scrollingTimeoutId)
    scrollingTimeoutId = window.setTimeout(() => {
      callback()
    }, 250)
  }

  scrollWrapperNode?.addEventListener('scroll', scrollHandler)

  return () => {
    scrollWrapperNode?.removeEventListener('scroll', scrollHandler)
  }
}

export const flattenEntries = (
  entries: DraftQuestionnaireEntry[]
): DraftQuestionnaireEntry[] => {
  return entries.flatMap((entry) => {
    return entry.entryType === EntryType.SectionEntryType
      ? // @todo Legacy eslint violation – fix this when editing
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        (entry.entryItem as DraftSectionItem).entries || []
      : entry
  })
}

export const getMatrixRowsByQuestion = (
  flatAllEntries: DraftQuestionnaireEntry[]
) => {
  const matrixRowsByQuestion: MatrixRowsByQuestion = {}

  for (const entry of flatAllEntries) {
    if (entry.entryType === EntryType.MatrixEntryType) {
      const entryItem = entry.entryItem
      matrixRowsByQuestion[entryItem.matrixTitleLk] = entryItem.matrixRows
    }
  }

  return matrixRowsByQuestion
}

export const getResponseOptionsByQuestion = (
  flatAllEntries: DraftQuestionnaireEntry[]
) => {
  const responseOptionsByQuestion: ResponseOptionsByQuestion = {}

  for (const { entryItem, entryType } of flatAllEntries) {
    switch (entryType) {
      case EntryType.QuestionEntryType: {
        responseOptionsByQuestion[entryItem.questionLk] =
          entryItem.responseOptions
        break
      }
      case EntryType.MatrixEntryType: {
        responseOptionsByQuestion[entryItem.matrixTitleLk] =
          entryItem.responseOptions
        break
      }
      // Add cases for other entry types if needed
    }
  }

  return responseOptionsByQuestion
}

export const getSettingsByQuestion = (
  flatAllEntries: DraftQuestionnaireEntry[]
) => {
  const settingsByQuestion: Record<string, EntrySettingValue[]> = {}

  for (const { entryItem, entryType } of flatAllEntries) {
    switch (entryType) {
      case EntryType.QuestionEntryType: {
        settingsByQuestion[entryItem.questionLk] = entryItem.settingValues
        break
      }
      case EntryType.MatrixEntryType: {
        settingsByQuestion[entryItem.matrixTitleLk] = entryItem.settingValues
        break
      }
      case EntryType.TextCardEntryType: {
        settingsByQuestion[entryItem.textCardLk] = entryItem.settingValues
        break
      }
    }
  }

  return settingsByQuestion
}

export const getValidationErrors = (
  flatAllEntries: DraftQuestionnaireEntry[]
) => {
  const validationErrors: Record<string, ValidationError> = {}

  const getValidationInfo = (entry: DraftQuestionnaireEntry) => {
    const { entryType, entryItem } = entry
    switch (entryType) {
      case EntryType.QuestionEntryType:
        return {
          key: entryItem.questionLk,
          validate: () =>
            validateQuestionEntry(entry.position, entryItem, flatAllEntries)
        }
      case EntryType.MatrixEntryType:
        return {
          key: entryItem.matrixTitleLk,
          validate: () =>
            validateMatrixEntry(entry.position, entryItem, flatAllEntries)
        }
      case EntryType.TextCardEntryType:
        return {
          key: entryItem.textCardLk,
          validate: () =>
            validateTextCardEntry(entry.position, entryItem, flatAllEntries)
        }
      case EntryType.ForkEntryType:
        return {
          key: entryItem.fork.forkId,
          validate: () => validateForkEntry(entryItem)
        }
    }
  }

  for (const entry of flatAllEntries) {
    const validationInfo = getValidationInfo(entry)
    if (validationInfo) {
      const errors = validationInfo.validate()
      if (errors) {
        validationErrors[validationInfo.key] = errors
      }
    }
  }

  return validationErrors
}

export const getQuestionLogicByQuestion = (
  flatAllEntries: DraftQuestionnaireEntry[]
) => {
  const questionLogicByQuestion: Record<
    string,
    DraftLogicClauseProposition[][]
  > = {}

  for (const { entryItem, entryType, entryId } of flatAllEntries) {
    if (
      entryType === EntryType.MatrixEntryType ||
      entryType === EntryType.QuestionEntryType ||
      entryType === EntryType.TextCardEntryType
    ) {
      questionLogicByQuestion[entryId] = entryItem.questionLogic
    }
  }

  return questionLogicByQuestion
}

export const flattenAllEntries = (draftQuestionnaire: DraftQuestionnaire) =>
  flattenEntries(
    draftQuestionnaire.entries.concat(draftQuestionnaire.audienceEntries)
  )

export const getNextEntryPosition = (entry: DraftQuestionnaireEntry) => {
  if (entry.entryType === EntryType.SectionEntryType) {
    return entry.position + entry.entryItem.entries.length + 1
  }

  return entry.position + 1
}

export const getEntryId = (entry: DraftQuestionnaireEntry) => {
  switch (entry.entryType) {
    case EntryType.QuestionEntryType:
      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      return entry.entryItem?.questionLk
    case EntryType.MatrixEntryType:
      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      return entry.entryItem?.matrixTitleLk
    case EntryType.SectionEntryType:
      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      return entry.entryItem?.sectionId
    case EntryType.TextCardEntryType:
      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      return entry.entryItem?.textCardLk
    case EntryType.ForkEntryType:
      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      return entry.entryItem?.fork.forkId
    default:
      throw new Error('Unknown entry type')
  }
}

export const ingestQuestionnaireStateFromApollo = (
  state: Draft<QuestionnaireState>,
  draftQuestionnaire: DraftQuestionnaire
) => {
  const flatAllEntries = flattenAllEntries(draftQuestionnaire)

  state.matrixRowsByQuestion = getMatrixRowsByQuestion(flatAllEntries)
  state.responseOptionsByQuestion = getResponseOptionsByQuestion(flatAllEntries)
  state.settingsByQuestion = getSettingsByQuestion(flatAllEntries)
  state.validationErrorsByQuestion = getValidationErrors(flatAllEntries)
  state.questionLogicByQuestion = getQuestionLogicByQuestion(flatAllEntries)
}

export const getScrollId = (entryId: string, scrollIdSuffix?: string) => {
  const scrollId = `${SCROLL_ID_PREFIX}${entryId}`
  return scrollIdSuffix ? `${scrollId}-${scrollIdSuffix}` : scrollId
}
