import {
  ExclusiveButton,
  IconColor,
  IconName,
  KebabMenu,
  KebabMenuIconPosition,
  KebabMenuOption,
  ListItemAudienceResponseOption
} from '@focaldata/cin-ui-components'
import { ClipboardEvent, useState } from 'react'
import { CustomOptionValidationError } from '../../../data/gql-gen/fieldwork/graphql'
import { draftAudienceRefetchQuery } from '../../../data/gql/questionnaire/queries/audiences'
import { DraftCustomAudienceCriterionOption } from '../../../data/model/audience'
import { CustomCriterionValidationErrors } from '../../../data/model/fieldwork'
import { MediaType } from '../../../data/model/questionnaire'
import { usePasteCustomCriterionOptionV2 } from '../../../hooks/audience/pasteCustomCriterionOptionV2'
import { useUpdateAudienceMemberV2 } from '../../../hooks/audience/updateAudienceMemberV2'
import { useUpdateCustomCriterionOptionV2 } from '../../../hooks/audience/updateCustomCriterionOptionV2'
import {
  useNotifyingDefaultState,
  useNotifyingState
} from '../../../hooks/useNotifyingState'
import { useProjectId } from '../../../hooks/useProjectId'
import { useSurveyId } from '../../../hooks/useSurveyId'
import {
  isQuotaValueZeroError,
  numberToFixedString,
  proportionToPercent
} from '../../../utils/HelperFunctions'
import { isFocaldataUser } from '../../../utils/authUtils'
import RowMediaUploader from '../../Questionnaire/MediaUploader/MediaUploader'
import { useResponseOptionsLogic } from '../AudienceQuestionCardFooter/useResponseOptionsLogic'
import { ForceDisplay } from '../ForceDisplayCustomAudienceResponse'
import {
  PinCustomAudienceResponse,
  PinnedItemType
} from '../PinCustomAudienceResponse'
import { isCustomResponseOptionEmpty } from '../utils'

const CustomAudienceResponseOption = ({
  questionLk,
  ariaLabel,
  responseOption,
  cannotDelete,
  validationErrors,
  qualifyingEnabled,
  quotasEnabled,
  isAudienceInvalid,
  isRandomiseOn,
  isMultiSelect,
  matchedWithLoopSource,
  onToggleQualification,
  onUpdateQuotaValues,
  onDeleteOption,
  onSetCustomQuota,
  onUpdateOptionText
}: {
  questionLk: string
  ariaLabel: string
  responseOption: DraftCustomAudienceCriterionOption
  cannotDelete: boolean
  validationErrors?: CustomCriterionValidationErrors
  qualifyingEnabled: boolean
  quotasEnabled: boolean
  isAudienceInvalid?: boolean
  isRandomiseOn?: boolean
  isMultiSelect?: boolean
  matchedWithLoopSource: boolean
  onToggleQualification: (
    option: DraftCustomAudienceCriterionOption,
    newValue: boolean
  ) => void
  onUpdateQuotaValues?: (responseOptionLk: string, value: number) => void
  onDeleteOption: (responseOptionLk: string) => void
  onSetCustomQuota: (responseOptionLk: string, percent: number) => void
  onUpdateOptionText: (
    responseOption: DraftCustomAudienceCriterionOption,
    nextText: string
  ) => void
}) => {
  const responseOptionsLogic = useResponseOptionsLogic(questionLk)
  const { updateAudienceMemberV2 } = useUpdateAudienceMemberV2()
  const [optionText, setOptionText] = useNotifyingDefaultState(
    responseOption.responseOption.value,
    (nextText) => onUpdateOptionText(responseOption, nextText),
    500
  )
  const projectId = useProjectId()
  const surveyId = useSurveyId()
  const [isImageDialogOpened, setIsImageDialogOpened] = useState(false)

  const [, setDraftQuotaValue] = useNotifyingState(
    responseOption.quota?.percent ?? 0,
    (nextValue) => onSetCustomQuota(responseOption.responseOptionLk, nextValue),
    500
  )
  const updateCustomCriterionOptionV2 = useUpdateCustomCriterionOptionV2()

  const handlePinCustomAudienceResponseOption = async () => {
    await updateCustomCriterionOptionV2({
      variables: {
        input: {
          projectId,
          surveyId,
          questionId: questionLk,
          responseOptionId: responseOption.responseOptionLk,
          pinned: true
        }
      }
    })
  }

  const setExclusiveResponseOptionFunc = (isExclusive: boolean) => async () => {
    await updateCustomCriterionOptionV2({
      variables: {
        input: {
          projectId,
          surveyId,
          questionId: questionLk,
          responseOptionId: responseOption.responseOptionLk,
          exclusive: isExclusive
        }
      }
    })
  }

  const handleTogglePinCustomAudienceResponseOption = async () => {
    await updateCustomCriterionOptionV2({
      variables: {
        input: {
          projectId,
          surveyId,
          questionId: questionLk,
          responseOptionId: responseOption.responseOptionLk,
          pinned: false
        }
      }
    })
  }

  const responseActions: KebabMenuOption[] = []
  if (isRandomiseOn) {
    responseActions.push({
      id: 0,
      textItem: 'Pin response',
      disabled: responseOption.pinned,
      iconName: IconName.PushPin,
      onClickItem: handlePinCustomAudienceResponseOption
    })
  }
  if (isMultiSelect) {
    responseActions.push({
      id: 1,
      textItem: 'Make exclusive',
      disabled: responseOption.exclusive,
      iconName: IconName.Filter1Outlined,
      onClickItem: setExclusiveResponseOptionFunc(true)
    })
  }

  responseActions.push({
    id: 2,
    textItem: 'Add image',
    disabled: !!responseOption.media,
    iconName: IconName.Image,
    onClickItem: () => setIsImageDialogOpened(true)
  })

  const maxDisplayedOptionsEnabled = !!responseOptionsLogic
  const hasForceDisplay =
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    responseOptionsLogic?.exemptResponseOptionIds?.includes(
      responseOption.responseOptionLk
    )

  if (isMultiSelect && isFocaldataUser() && maxDisplayedOptionsEnabled) {
    responseActions.push({
      id: 3,
      textItem: 'Force display',
      iconName: IconName.Visibility,
      disabled: hasForceDisplay,
      onClickItem: async () => {
        await updateAudienceMemberV2({
          variables: {
            input: {
              projectId,
              surveyId,
              memberId: questionLk,
              updateCustomMember: {
                responseOptionsLogic: {
                  value: {
                    exemptResponseOptionLks: [
                      ...responseOptionsLogic.exemptResponseOptionIds,
                      responseOption.responseOptionLk
                    ]
                  }
                }
              }
            }
          },
          refetchQueries: [draftAudienceRefetchQuery(projectId, surveyId)]
        })
      }
    })
  }

  const handleMediaUpload = async (
    surveyId: string,
    questionLk: string,
    responseOptionLk: string,
    mediaUrl: string,
    mediaName: string,
    mediaType: MediaType,
    renderedMediaUrl: string
  ) => {
    await updateCustomCriterionOptionV2({
      variables: {
        input: {
          projectId,
          surveyId,
          questionId: questionLk,
          responseOptionId: responseOptionLk,
          media: {
            value: {
              mediaUrl,
              mediaName,
              mediaType,
              renderedMediaUrl
            }
          }
        }
      }
    })
  }

  const handleRemoveMedia = async (
    questionLk: string,
    responseOptionLk: string
  ) => {
    await updateCustomCriterionOptionV2({
      variables: {
        input: {
          projectId,
          surveyId,
          questionId: questionLk,
          responseOptionId: responseOptionLk,
          media: {
            value: null
          }
        }
      }
    })
  }

  const handleRemoveForceDisplay = async () => {
    await updateAudienceMemberV2({
      variables: {
        input: {
          projectId,
          surveyId,
          memberId: questionLk,
          updateCustomMember: {
            responseOptionsLogic: {
              value: {
                exemptResponseOptionLks:
                  responseOptionsLogic?.exemptResponseOptionIds.filter(
                    (responseOptionLk) =>
                      responseOptionLk !== responseOption.responseOptionLk
                  )
              }
            }
          }
        }
      },
      refetchQueries: [draftAudienceRefetchQuery(projectId, surveyId)]
    })
  }

  const pasteCriterionOptions = usePasteCustomCriterionOptionV2(
    questionLk,
    responseOption.position
  )

  const handleOnPaste = async (event: ClipboardEvent<HTMLInputElement>) => {
    await pasteCriterionOptions(event)
  }

  let errorText = ''
  let hasError = false

  if (
    isAudienceInvalid &&
    isCustomResponseOptionEmpty(
      responseOption.responseOptionLk,
      validationErrors
    )
  ) {
    errorText = "You can't leave a response option blank"
    hasError = true
  } else if (
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    validationErrors?.customCriterionOptionsErrors?.some(
      ({ responseOptionLk, errors }) =>
        responseOptionLk === responseOption.responseOptionLk &&
        errors.includes(
          CustomOptionValidationError.ResponseOptionNotExemptOrMappedError
        )
    )
  ) {
    errorText = 'Response option must be mapped or exempt'
    hasError = true
  }

  return (
    <ListItemAudienceResponseOption
      responseOptionId={responseOption.responseOptionLk}
      sx={{
        '& .MuiInputBase-root': {
          border: matchedWithLoopSource ? '1px solid var(--primary)' : undefined
        }
      }}
      index={responseOption.position}
      draggableId={`drag-${responseOption.responseOptionLk}`}
      ariaLabel={ariaLabel}
      canBeReordered
      isError={hasError}
      value={optionText}
      cannotDelete={cannotDelete}
      disableGutters
      placeholder="Enter response option"
      helperText={errorText}
      isQuotaValueError={isQuotaValueZeroError(
        validationErrors,
        responseOption.responseOptionLk
      )}
      errorMessageQuotaValue="Quotas cannot be 0%. Turn off qualifying or increase the quota %."
      editable
      hasQualification={qualifyingEnabled}
      isQualifying={!!responseOption.qualification}
      onQualifyingChange={(newValue: boolean) => {
        onToggleQualification(responseOption, newValue)
      }}
      onDelete={onDeleteOption}
      onChange={(value: string) => {
        setOptionText(value)
      }}
      onPaste={handleOnPaste}
      hasQuota={quotasEnabled}
      disableQuotaInput={!responseOption.qualification}
      quotaValue={
        responseOption.quota
          ? numberToFixedString(
              proportionToPercent(responseOption.quota.percent)
            )
          : undefined
      }
      onQuotaChange={(value) => {
        setDraftQuotaValue(parseFloat(value))
        onUpdateQuotaValues?.(
          responseOption.responseOptionLk,
          parseFloat(value)
        )
      }}
      pinningIconButton={
        responseOption.pinned && isRandomiseOn ? (
          <PinCustomAudienceResponse
            itemType={PinnedItemType.ResponseOption}
            isPinned={responseOption.pinned}
            handleTogglePinned={handleTogglePinCustomAudienceResponseOption}
          />
        ) : undefined
      }
      exclusiveButton={
        responseOption.exclusive ? (
          <ExclusiveButton
            onExclusiveIconClick={setExclusiveResponseOptionFunc(false)}
          />
        ) : undefined
      }
      responseActionsMenu={
        responseActions.length > 0 ? (
          <KebabMenu
            kebabMenuOptions={responseActions}
            horizontal
            iconPosition={KebabMenuIconPosition.Left}
            iconColor={IconColor.Background}
            tooltipText="Configure this response option"
          />
        ) : undefined
      }
      mediaButton={
        <RowMediaUploader
          questionnaireId={surveyId}
          questionLk={questionLk}
          responseOptionLk={responseOption.responseOptionLk}
          mediaUrl={responseOption.media?.mediaUrl}
          renderedMediaUrl={responseOption.media?.renderedMediaUrl}
          mediaType={responseOption.media?.mediaType}
          imageDialogOpened={isImageDialogOpened}
          onDialogClose={() => setIsImageDialogOpened(false)}
          onMediaUpload={handleMediaUpload}
          onRemoveMedia={handleRemoveMedia}
        />
      }
      forceDisplayButton={
        hasForceDisplay ? (
          <ForceDisplay onRemoveForceDisplay={handleRemoveForceDisplay} />
        ) : undefined
      }
    />
  )
}

export default CustomAudienceResponseOption
