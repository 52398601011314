import gql from 'graphql-tag'

export const CREATE_AUDIENCE_MEMBER_V2 = gql(`
  mutation createAudienceMemberV2(
    $input: CreateAudienceMemberV2Input!
    $skipIRSNotificationRecording: Boolean = false
  ) {
    recordAudienceChangedForIRSNotification @client(always: true)
    @skip(if: $skipIRSNotificationRecording) 
    createAudienceMemberV2(input: $input) {
      ...DraftAudienceMember
    }
  }
`)
