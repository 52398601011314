import { AxiosError } from 'axios'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { z } from 'zod'
import { LogAmplitudeEvent, SetUserId } from '../../../amplitude'
import { EventType } from '../../../amplitude/eventType'
import { Login as LoginControl } from '../../../components/Auth'
import { Login } from '../../../data/rest/accountUser'
import UserSession from '../../../utils/UserSession'
import { AccessToken, setAccountId } from '../../../utils/authUtils'

const successStatus = {
  ok: 200,
  created: 201
}

const failStatus = {
  unauthorized: 401,
  notFound: 404
}

const projectSetupHostSchema = z.string().url()
const envProjectSetupHost =
  process.env.REACT_APP_PROJECT_SETUP_HOST || 'https://app.focaldata.dev' //TODO: RESTORE THIS IS IT IS ONLY USED FOR STAGING
const projectSetupHost = (
  process.env.NODE_ENV === 'development'
    ? projectSetupHostSchema.default('http://localhost:3000')
    : projectSetupHostSchema
).parse(envProjectSetupHost.replace(/\/$/, ''))

const appTargetSchema = z
  .enum(['survey_builder', 'project_setup'])
  .default('survey_builder')
  .catch('survey_builder')

const targetDefaults: Record<z.infer<typeof appTargetSchema>, string> = {
  survey_builder: '/projects',
  project_setup: '/'
}

const searchSchema = z
  .object({
    app: appTargetSchema,
    target: z.string().optional()
  })
  .transform((data) => ({
    ...data,
    target: data.target ?? targetDefaults[data.app]
  }))

const LoginComponent = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const handleLogin = async (email: string, password: string) => {
    try {
      const response = await Login(email, password)
      if (response.status === successStatus.ok) {
        UserSession.setEmail(response.data.emailAddress)
        AccessToken.set(response.data.accessToken)
        const gotAccountId = await setAccountId(
          response.data.emailAddress,
          response.data.accessToken
        )
        SetUserId(response.data.emailAddress)
        LogAmplitudeEvent(EventType.Loggedin)

        const { app, target } = searchSchema.parse(
          Object.fromEntries(searchParams.entries())
        )

        switch (app) {
          case 'survey_builder':
            navigate(target)
            break
          case 'project_setup':
            window.location.href =
              projectSetupHost + '/' + target.replace(/^\/+/g, '')
            break
        }

        return gotAccountId
      }
    } catch (error: unknown) {
      const ex = error as AxiosError
      if (ex.response && ex.response.status === failStatus.unauthorized) {
        return new Error(failStatus.unauthorized.toString())
      }
      if (ex.response && ex.response.status === failStatus.notFound) {
        return new Error(failStatus.notFound.toString())
      }
      return new Error(ex.message)
    }
    return false
  }

  const handleGoToRegister: () => void = () => {
    navigate('/signup')
  }

  const handleForgotPassword: () => void = () => {
    navigate('/forgot-password')
  }

  return (
    <LoginControl
      onLogin={handleLogin}
      onForgotPassword={handleForgotPassword}
      onGoToRegister={handleGoToRegister}
    />
  )
}

export default LoginComponent
