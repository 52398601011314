import { FetchResult, useFragment, useMutation } from '@apollo/client'
import { useContext } from 'react'
import { LogAmplitudeEvent } from '../../amplitude'
import { EventType } from '../../amplitude/eventType'
import { questionBeingEditedId } from '../../apollo/apolloClient'
import {
  AddSectionMutationVariables,
  DraftSectionItem,
  EntryType
} from '../../data/gql-gen/questionnaire/graphql'
import { DRAFT_SECTION_ITEM } from '../../data/gql/questionnaire/fragments/draftSectionItem'
import {
  QUESTIONNAIRE,
  draftQuestionnaireRefetchQuery
} from '../../data/gql/questionnaire/queries'
import { LoggerErrorType } from '../../data/logger'
import { useRemoveQuestionnaireEntries } from '../../hooks/questionnaire/useRemoveQuestionnaireEntries'
import { useProjectId } from '../../hooks/useProjectId'
import { questionNewlyCreatedNumber } from '../../hooks/useResetNewlyCreatedEntry'
import { useSurveyId } from '../../hooks/useSurveyId'
import { scrollToQuestionCard } from '../../modules/Questionnaire/QuestionCommon/QuestionCommon.utils'
import QuestionnaireContext, {
  closeDeleteSectionDialog
} from '../../modules/Questionnaire/Questionnaire.context'
import { deleteQuestionTransactionDatadog } from '../../tracking/perf/transactions'
import { captureApolloError } from '../../utils/HelperFunctions'
import { ADD_SECTION, AddSectionData } from './Section.mutations'
import { updateCacheOnSectionDelete } from './Section.utils'

const useAddSection = (questionnaireId: string) => {
  const projectId = useProjectId()
  const surveyId = useSurveyId()

  const [addSectionMutation] = useMutation<
    AddSectionData,
    AddSectionMutationVariables
  >(ADD_SECTION, {
    context: { clientName: 'questionnaire' },
    refetchQueries: [draftQuestionnaireRefetchQuery(projectId, surveyId)],
    onCompleted: ({ addSection }) => {
      questionBeingEditedId(undefined)
      questionNewlyCreatedNumber(addSection.number)
      scrollToQuestionCard(addSection.id, EntryType.SectionEntryType)
    },
    onError: (error) => {
      captureApolloError(LoggerErrorType.ApolloMutation, 'addSection', error)
    }
  })

  return (
    sectionPosition: number
  ): Promise<
    FetchResult<AddSectionData, Record<string, any>, Record<string, any>>
  > => {
    return addSectionMutation({
      variables: {
        questionnaireId,
        title: 'Section',
        position: sectionPosition
      }
    })
  }
}

const useDeleteSection = (
  entryId: string
): {
  deleteSectionWithoutQuestions: () => void
  deleteSectionAndQuestions: () => void
} => {
  const removeQuestionnaireEntries = useRemoveQuestionnaireEntries()
  const { dispatch } = useContext(QuestionnaireContext)
  const projectId = useProjectId()
  const surveyId = useSurveyId()
  const { data: sectionItem, complete } = useFragment<DraftSectionItem>({
    fragment: DRAFT_SECTION_ITEM,
    fragmentName: 'DraftSectionItem',
    from: { __typename: 'DraftSectionItem', sectionId: entryId },
    canonizeResults: true
  })

  const deleteSection = async (includingEntries: boolean) => {
    dispatch(closeDeleteSectionDialog())
    deleteQuestionTransactionDatadog.start()
    const sectionEntriesIds = complete
      ? sectionItem.entries.map((entry) => entry.id)
      : []

    await removeQuestionnaireEntries(
      includingEntries ? [entryId, ...sectionEntriesIds] : [entryId],
      {
        refetchQueries: [QUESTIONNAIRE],
        onCompleted: () => {
          LogAmplitudeEvent(EventType.DeletedSection, {
            projectId,
            surveyId,
            entryId,
            includingEntries
          })
        },
        update: (cache) =>
          updateCacheOnSectionDelete(cache, {
            projectId,
            surveyId,
            entryId,
            includingEntries
          })
      }
    )
  }

  return {
    deleteSectionWithoutQuestions: () => deleteSection(false),
    deleteSectionAndQuestions: () => deleteSection(true)
  }
}

export { useAddSection, useDeleteSection }
