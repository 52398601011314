import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { LogAmplitudeEvent } from '../../../amplitude'
import { EventType } from '../../../amplitude/eventType'
import { useGetSoftLaunch } from '../../../components/SoftLaunch/SoftLaunch.hooks'
import { SoftLaunch } from '../../../components/SoftLaunch/SoftLaunch.model'
import { SurveyParams } from '../../../data/model/surveyParams'
import { useUpdateFieldworkV2 } from '../../../hooks/audience/createOrUpdateFieldworkV2'
import ConfirmationSoftLaunchControl from './ConfirmationSoftLaunch.control'

interface Props {
  toggleOn?: boolean
  startNumOfCompletes?: number
  minNumOfCompletes?: number
}

const ConfirmationSoftLaunchContainer: React.FC<Props> = (props: Props) => {
  const params = useParams<keyof SurveyParams>()
  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const surveyId = params.surveyId!
  const {
    toggleOn = false,
    startNumOfCompletes = 100,
    minNumOfCompletes = 1
  }: Props = props

  const firstFetchPolicy = 'no-cache'
  const {
    isConfiguredForSoftLaunch,
    softLaunchSamplesNeeded,
    samplesNeeded: fieldworkSamplesNeeded
  } = useGetSoftLaunch(firstFetchPolicy)

  const { updateFieldworkV2 } = useUpdateFieldworkV2()
  const minfieldworkSamplesNeeded = fieldworkSamplesNeeded * 0.1
  const defaultSoftLaunchSamplesNeeded =
    startNumOfCompletes < fieldworkSamplesNeeded
      ? startNumOfCompletes
      : Math.ceil(minfieldworkSamplesNeeded)
  const minNumOfCompletesErrorMessage = `Number of completes must be between ${minNumOfCompletes} and ${fieldworkSamplesNeeded}`
  const infoText =
    'Launch your project initially with a fraction of the sample. Check for any issues. Then launch fully and see all data together in one place.'

  const [isToggledOn, setIsToggledOn] = useState<boolean>(toggleOn)
  const [softLaunchNumOfCompletes, setSoftLaunchNumOfCompletes] =
    useState<number>(defaultSoftLaunchSamplesNeeded)
  const [hasInputError, setHasInputError] = useState<boolean>(false)

  const handleUpdateFieldworkSoftLaunch: (value: any) => void = useCallback(
    (value) => {
      const softLaunch: SoftLaunch = {
        samplesNeeded: Number(value)
      }
      updateFieldworkV2({
        variables: {
          input: { surveyId, panelSupplierCode: 'cint', softLaunch }
        }
      })
    },
    [surveyId, updateFieldworkV2]
  )

  const handleToggle: () => void = () => {
    const newIsToggleOn = !isToggledOn
    const softLaunchCompletes = newIsToggleOn
      ? defaultSoftLaunchSamplesNeeded
      : 0

    setIsToggledOn(newIsToggleOn)
    setSoftLaunchNumOfCompletes(softLaunchCompletes)
    handleUpdateFieldworkSoftLaunch(softLaunchCompletes)

    if (newIsToggleOn)
      LogAmplitudeEvent(EventType.ToggledSoftLaunch, { surveyId })
  }

  const handleInputBlur: (value: string) => void = (value) => {
    const numValue = Number(value)

    if (numValue >= minNumOfCompletes && numValue <= fieldworkSamplesNeeded) {
      setSoftLaunchNumOfCompletes(numValue)
      handleUpdateFieldworkSoftLaunch(value)
      setHasInputError(false)

      LogAmplitudeEvent(EventType.ChangedSoftLaunchCompletes, {
        surveyId,
        softLaunchCompletes: numValue,
        fieldworkSamplesNeeded,
        softLaunchPercentage: numValue / fieldworkSamplesNeeded
      })
    } else {
      setHasInputError(true)
    }
  }

  const toggleSoftLaunchOff: () => void = useCallback(() => {
    setIsToggledOn(false)
    setSoftLaunchNumOfCompletes(0)
    handleUpdateFieldworkSoftLaunch(0)
  }, [
    setIsToggledOn,
    setSoftLaunchNumOfCompletes,
    handleUpdateFieldworkSoftLaunch
  ])

  useEffect(() => {
    // set toggleOn state here
    setIsToggledOn(isConfiguredForSoftLaunch)

    // set startNumOfCompletes state here
    if (
      softLaunchSamplesNeeded >= minNumOfCompletes &&
      softLaunchSamplesNeeded <= fieldworkSamplesNeeded
    )
      setSoftLaunchNumOfCompletes(softLaunchSamplesNeeded)
    else if (softLaunchSamplesNeeded > fieldworkSamplesNeeded)
      toggleSoftLaunchOff()
    else if (minfieldworkSamplesNeeded > minNumOfCompletes)
      setSoftLaunchNumOfCompletes(minfieldworkSamplesNeeded)
    else if (startNumOfCompletes < fieldworkSamplesNeeded)
      setSoftLaunchNumOfCompletes(startNumOfCompletes)
    else setSoftLaunchNumOfCompletes(minNumOfCompletes)
  }, [
    softLaunchSamplesNeeded,
    startNumOfCompletes,
    minfieldworkSamplesNeeded,
    fieldworkSamplesNeeded,
    minNumOfCompletes,
    isConfiguredForSoftLaunch,
    toggleSoftLaunchOff,
    surveyId
  ])

  // Use debounce for num input or on blur

  return (
    <ConfirmationSoftLaunchControl
      isToggledOn={isToggledOn}
      inputPlaceholder={startNumOfCompletes}
      softLaunchSamplesNeeded={softLaunchNumOfCompletes}
      onToggle={handleToggle}
      onInputBlur={handleInputBlur}
      hasInputError={hasInputError}
      inputErrorMessage={minNumOfCompletesErrorMessage}
      infoText={infoText}
    />
  )
}

export default ConfirmationSoftLaunchContainer
