import gql from 'graphql-tag'
import { DraftQuestionnaireEntry } from '../../data/model/questionnaire'

export interface AddSectionData {
  addSection: DraftQuestionnaireEntry
}

export const ADD_SECTION = gql`
  mutation addSection(
    $questionnaireId: ID!
    $title: String!
    $position: Int!
    $setting: SettingInputInput
    $defaultSectionId: ID
  ) {
    addSection(
      questionnaireId: $questionnaireId
      title: $title
      position: $position
      setting: $setting
      defaultSectionId: $defaultSectionId
    ) {
      id
      number
      position
      entryType
      questionKind
      entryItem {
        __typename
        ... on DraftSectionItem {
          title
          sectionId
          __typename
        }
      }
      __typename
    }
  }
`
