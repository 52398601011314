import {
  InMemoryCache,
  createFragmentRegistry,
  makeVar
} from '@apollo/client/cache'
import { ApolloClient } from '@apollo/client/core/ApolloClient'
import { DraftSectionItem } from '../components/Section/Section.model'
import { draftCombinedQuestionMutationsResolvers } from '../containers/Page/MultiMarket/ResponseOptionsMappingPage/DraftCombinedQuestionState/DraftCombinedQuestion.resolvers'
import dashboardFragments from '../data/gql-gen/dashboard/fragments.json'
import { Project } from '../data/gql-gen/dashboard/graphql'
import fieldworkFragments from '../data/gql-gen/fieldwork/fragments.json'
import localFragments from '../data/gql-gen/local/fragments.json'
import questionnaireFragments from '../data/gql-gen/questionnaire/fragments.json'
import { TEXT_CARD } from '../data/gql/questionnaire/fragments/DraftTextCardItem'
import {
  CUSTOM_CRITERION_VALIDATION_ERRORS,
  STANDARD_CRITERION_VALIDATION_ERRORS
} from '../data/gql/questionnaire/fragments/criterionValidationErrors'
import DRAFT_AUDIENCE_MEMBER from '../data/gql/questionnaire/fragments/draftAudienceMember'
import { DRAFT_CUSTOM_AUDIENCE_ITEM } from '../data/gql/questionnaire/fragments/draftCustomAudienceItem'
import DRAFT_RESPONSE_OPTION from '../data/gql/questionnaire/fragments/draftEntryResponseOption'
import DRAFT_MASKING_RULE from '../data/gql/questionnaire/fragments/draftMaskingRule'
import {
  DRAFT_MATRIX_ITEM,
  MATRIX_TITLE
} from '../data/gql/questionnaire/fragments/draftMatrixItem'
import { DRAFT_MATRIX_ITEM_QUALIFICATION } from '../data/gql/questionnaire/fragments/draftMatrixItemQualification'
import DRAFT_MATRIX_ROW from '../data/gql/questionnaire/fragments/draftMatrixRow'
import {
  DRAFT_QUESTION_ITEM,
  QUESTION_FRAGMENT
} from '../data/gql/questionnaire/fragments/draftQuestionItem'
import QUESTION_LOGIC from '../data/gql/questionnaire/fragments/questionLogic'
import QUESTION_MEDIA from '../data/gql/questionnaire/fragments/questionMedia'
import { RESPONSE_OPTIONS_LOGIC } from '../data/gql/questionnaire/fragments/responseOptionsLogic'
import { FIELDWORK_FRAGMENT } from '../data/gql/questionnaire/mutations/createOrUpdateFieldworkV2'
import {
  DraftQuestionnaireEntry,
  EntryType,
  QuestionSettingCode
} from '../data/model/questionnaire'
import { cacheSettings } from '../modules/Project/searchProjectsCacheSettings'
import { isSettingEnabled } from '../utils/questionnaireUtils'
import { getDraftFieldworkAudienceMemberKeyFields } from './apolloClient.helpers'
import { link } from './link'
import { writeAudienceChangedSinceLastSlackIRSNotification } from './localState/writeAudienceChangedSinceLastSlackIRSNotification'

export const questionBeingEditedId = makeVar<string | undefined>(undefined)

export const apolloCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        searchProjects: cacheSettings,
        projectBySurveyId: {
          keyArgs: ['surveyId'],
          merge(existing: Partial<Project> = {}, incoming: Partial<Project>) {
            if (existing.projectId === incoming.projectId) {
              return { ...existing, ...incoming }
            }
            return incoming
          }
        }
      }
    },
    DraftEntryResponseOption: {
      keyFields: ['responseOptionLk']
    },
    DraftMatrixRow: {
      keyFields: ['questionLk']
    },
    Fork: {
      keyFields: ['forkId']
    },
    DraftMatrixItem: {
      keyFields: ['matrixTitleLk']
    },
    MatrixTitle: {
      keyFields: ['matrixTitleId']
    },
    DraftTextCardItem: {
      keyFields: ['textCardLk']
    },
    DraftQuestionItem: {
      keyFields: ['questionLk']
    },
    Question: {
      keyFields: ['questionId']
    },
    TextCard: {
      keyFields: ['textCardId']
    },
    DraftQuestionnaire: {
      keyFields: ['questionnaireId']
    },
    QuestionValidationErrors: {
      keyFields: ['questionLk']
    },
    MatrixValidationErrors: {
      keyFields: ['matrixTitleLk']
    },
    ForkValidationErrors: {
      keyFields: ['forkId']
    },
    DraftFieldworkAudienceMember: {
      keyFields: getDraftFieldworkAudienceMemberKeyFields({
        DraftStandardAudienceItem: ['criterion', 'question', 'questionId'],
        DraftCustomAudienceItem: ['question', 'questionId']
      })
    },
    DraftCustomAudienceItem: {
      keyFields: ['questionLk']
    },
    DraftCustomAudienceCriterionOption: {
      keyFields: ['responseOptionLk']
    },
    DraftCustomCriterion: {
      keyFields: ['questionLk']
    },
    DraftSectionItem: {
      keyFields: ['sectionId']
    },
    CustomCriterionValidationErrors: {
      keyFields: ['questionLk']
    },
    StandardCriterionValidationErrors: {
      keyFields: ['code']
    },
    GQLSurveyOrder: {
      keyFields: ['surveyId']
    },
    Fieldwork: {
      keyFields: ['surveyId']
    }
  },
  possibleTypes: {
    ...questionnaireFragments.possibleTypes,
    ...fieldworkFragments.possibleTypes,
    ...dashboardFragments.possibleTypes,
    ...localFragments.possibleTypes
  },
  fragments: createFragmentRegistry(
    RESPONSE_OPTIONS_LOGIC,
    DRAFT_CUSTOM_AUDIENCE_ITEM,
    QUESTION_LOGIC,
    CUSTOM_CRITERION_VALIDATION_ERRORS,
    STANDARD_CRITERION_VALIDATION_ERRORS,
    DRAFT_QUESTION_ITEM,
    DRAFT_MATRIX_ITEM,
    DRAFT_MATRIX_ITEM_QUALIFICATION,
    QUESTION_FRAGMENT,
    MATRIX_TITLE,
    TEXT_CARD,
    DRAFT_RESPONSE_OPTION,
    DRAFT_MASKING_RULE,
    QUESTION_MEDIA,
    DRAFT_MATRIX_ROW,
    DRAFT_AUDIENCE_MEMBER,
    FIELDWORK_FRAGMENT
  )
})

const client = new ApolloClient({
  assumeImmutableResults: true,
  cache: apolloCache,
  resolvers: {
    DraftQuestionnaireEntry: {
      entryId: ({ entryType, entryItem }: DraftQuestionnaireEntry) => {
        switch (entryType) {
          case EntryType.QuestionEntryType:
            return entryItem.questionLk
          case EntryType.MatrixEntryType:
            return entryItem.matrixTitleLk
          case EntryType.SectionEntryType:
            return entryItem.sectionId
          case EntryType.TextCardEntryType:
            return entryItem.textCardLk
          case EntryType.ForkEntryType:
            return entryItem.fork.forkId
          default:
            return ''
        }
      }
    },
    DraftSectionItem: {
      isLoopingEnabled: ({ settingValues }: DraftSectionItem) => {
        return isSettingEnabled(settingValues, QuestionSettingCode.Looping)
      }
    },
    Mutation: {
      recordAudienceChangedForIRSNotification(
        _,
        __,
        { cache }: { cache: InMemoryCache }
      ) {
        writeAudienceChangedSinceLastSlackIRSNotification(cache, true)
      },
      ...draftCombinedQuestionMutationsResolvers
    }
  },
  link
})

writeAudienceChangedSinceLastSlackIRSNotification(client.cache, false)

export default client
