import { useReactiveVar } from '@apollo/client'
import isEmpty from 'lodash/isEmpty'
import { useCallback } from 'react'
import { emptyArr } from '../../constants/misc'
import {
  DraftForkItem,
  EntryType,
  MatrixColumnValidationError,
  MatrixRowValidationError,
  QuestionValidationError,
  ResponseOptionValidationError,
  SliderValidationError,
  ValidationError
} from '../../data/model/questionnaire'
import {
  getEntryId,
  getValidationErrors
} from '../../modules/Questionnaire/Questionnaire.utils'
import { newEntryId } from '../../utils/questionnaireUtils'
import { responseOptionLkNewlyAdded } from '../useResetNewlyCreatedEntry'
import useGetDraftQuestionnaire from './useGetDraftQuestionnaire'

export interface ValidationResult {
  hasError: boolean
  errorMessage?: string
  errorType?: SliderValidationError
}

interface UseQuestionnaireValidationData {
  hasValidationErrors: () => boolean
  entryHasValidationErrors: (entryId: string) => boolean
  getQuestionValidationErrors: (entryLk: string) => ValidationError | undefined
  getMatrixValidationErrors: (entryLk: string) => ValidationError | undefined
  validateMatrixQuestionTitle: (entryLk: string) => boolean
  validateMatrixRowText: (
    entryLk: string | undefined,
    questionLk: string
  ) => ValidationResult
  validateMatrixColumnText: (
    entryLk: string | undefined,
    responseOptionLk: string
  ) => ValidationResult
  validateBasicResponseOption: (
    entryLk: string | undefined,
    responseOptionLk: string
  ) => ValidationResult
  validateBasicQuestionText: (entryLk: string | undefined) => boolean
  validateSliderNumbers: (entryLk: string | undefined) => ValidationResult
  validateFreeTextResponseRange: (
    entryLk: string | undefined
  ) => ValidationResult
  hasUnclearedValidationErrors: () => boolean
  validateQuestionLogic: (entryId: string) => boolean
}

export const validationErrorText = {
  [ResponseOptionValidationError.EmptyResponseOptionValueError]:
    "You can't leave a response option blank",
  [ResponseOptionValidationError.DuplicateResponseOptionValueError]:
    'You can’t have a duplicate response option',
  [ResponseOptionValidationError.InvalidRoute]:
    "You can't route a response to a previous question",
  [MatrixColumnValidationError.EmptyMatrixColumnError]:
    "You can't leave a matrix column blank",
  [MatrixColumnValidationError.DuplicateMatrixColumnError]:
    "You can't have a duplicate matrix column",
  [MatrixRowValidationError.EmptyMatrixRowError]:
    "You can't leave a matrix row blank",
  [MatrixRowValidationError.DuplicateMatrixRowError]:
    "You can't have a duplicate matrix row",
  [SliderValidationError.SliderInvalidRangeError]:
    'Highest number should be bigger than lowest number',
  [SliderValidationError.SliderInvalidStepError]:
    'Increment is too big for the range',
  [QuestionValidationError.ResponseRangeMinCharactersExceedError]:
    'Minimum characters cannot be greater than maximum characters',
  [ResponseOptionValidationError.InvalidDisplayLogic]:
    'Please add source for display logic'
}

export const isNonEmptyResponseError = (error?: string) => {
  return error !== validationErrorText.EmptyResponseOptionValueError
}

export const isNonEmptyRowError = (error?: string) => {
  return error !== validationErrorText.EmptyMatrixRowError
}

const useQuestionnaireValidation = (): UseQuestionnaireValidationData => {
  const newlyCreatedEntryId = useReactiveVar(newEntryId)
  const newlyAddedResponseOptionLk = useReactiveVar(responseOptionLkNewlyAdded)
  const newlyAddedRowLk = useReactiveVar(responseOptionLkNewlyAdded)

  const {
    flattenedEntries: flattenedQuestionnaireEntries,
    draftQuestionnaire
  } = useGetDraftQuestionnaire()
  const flattenedEntries = flattenedQuestionnaireEntries.concat(
    draftQuestionnaire?.audienceEntries || []
  )
  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const validationErrors = getValidationErrors(flattenedEntries) || emptyArr

  const hasValidationErrors: () => boolean = () => {
    return !isEmpty(validationErrors)
  }

  const getQuestionValidationErrors: (
    entryLk: string
  ) => ValidationError | undefined = (entryLk) => {
    const errors = validationErrors[entryLk]
    return errors
  }

  const getMatrixValidationErrors: (
    entryLk: string
  ) => ValidationError | undefined = useCallback(
    (entryLk) => {
      const errors = validationErrors[entryLk]
      return errors as ValidationError
    },
    [validationErrors]
  )
  const validateBasicQuestionText: (entryLk: string | undefined) => boolean = (
    entryLk
  ) => {
    return (
      (entryLk &&
        newlyCreatedEntryId !== entryLk &&
        getQuestionValidationErrors(entryLk)?.errors?.includes(
          QuestionValidationError.EmptyQuestionTextError
        )) ||
      false
    )
  }

  const validateMatrixQuestionTitle: (entryLk: string) => boolean = (
    entryLk
  ) => {
    // make sure to also check that this is not a newly added item
    return (
      (newlyCreatedEntryId !== entryLk &&
        getMatrixValidationErrors(entryLk)?.errors?.includes(
          QuestionValidationError.EmptyQuestionTextError
        )) ||
      false
    )
  }

  const validateMatrixRowText: (
    entryLk: string | undefined,
    questionLk: string
  ) => ValidationResult = (entryLk, questionLk) => {
    const validationResult: ValidationResult = { hasError: false }

    const rowErrors =
      (entryLk &&
        getMatrixValidationErrors(entryLk)?.rowErrors?.find(
          (error) => error.questionLk === questionLk
        )?.errors) ||
      emptyArr

    const setOfErrors = new Set(rowErrors)

    if (setOfErrors.size > 0) {
      validationResult.hasError = true
    }

    const {
      EmptyMatrixRowError,
      DuplicateMatrixRowError,
      InvalidDisplayLogic
    } = MatrixRowValidationError

    if (setOfErrors.has(EmptyMatrixRowError)) {
      validationResult.errorMessage = validationErrorText[EmptyMatrixRowError]
    } else if (setOfErrors.has(DuplicateMatrixRowError)) {
      validationResult.errorMessage =
        validationErrorText[DuplicateMatrixRowError]
    } else if (setOfErrors.has(InvalidDisplayLogic)) {
      validationResult.errorMessage = validationErrorText[InvalidDisplayLogic]
    }
    return validationResult
  }

  const validateMatrixColumnText: (
    entryLk: string | undefined,
    responseOptionLk: string
  ) => ValidationResult = useCallback(
    (entryLk, responseOptionLk) => {
      const validationResult: ValidationResult = { hasError: false }

      const columnErrors =
        (entryLk &&
          getMatrixValidationErrors(entryLk)?.responseOptionErrors?.find(
            (error) => error.responseOptionLk === responseOptionLk
          )?.errors) ||
        emptyArr

      const setOfErrors = new Set(columnErrors)

      if (setOfErrors.size > 0) {
        validationResult.hasError = true
      }

      const {
        EmptyResponseOptionValueError,
        DuplicateResponseOptionValueError
      } = ResponseOptionValidationError

      if (setOfErrors.has(EmptyResponseOptionValueError)) {
        validationResult.errorMessage =
          validationErrorText[EmptyResponseOptionValueError]
      } else if (setOfErrors.has(DuplicateResponseOptionValueError)) {
        validationResult.errorMessage =
          validationErrorText[DuplicateResponseOptionValueError]
      }

      return validationResult
    },
    [getMatrixValidationErrors]
  )

  const validateBasicResponseOption: (
    entryLk: string | undefined,
    responseOptionLk: string
  ) => ValidationResult = (entryLk, responseOptionLk) => {
    const validationResult: ValidationResult = { hasError: false }

    const responseOptionErrors =
      (entryLk &&
        getQuestionValidationErrors(entryLk)?.responseOptionErrors?.find(
          (error) => error.responseOptionLk === responseOptionLk
        )?.errors) ||
      emptyArr

    const setOfErrors = new Set(responseOptionErrors)

    if (setOfErrors.size > 0) {
      validationResult.hasError = true
    }

    const {
      EmptyResponseOptionValueError,
      DuplicateResponseOptionValueError,
      InvalidRoute,
      InvalidDisplayLogic
    } = ResponseOptionValidationError

    if (setOfErrors.has(EmptyResponseOptionValueError)) {
      validationResult.errorMessage =
        validationErrorText[EmptyResponseOptionValueError]
    } else if (setOfErrors.has(DuplicateResponseOptionValueError)) {
      validationResult.errorMessage =
        validationErrorText[DuplicateResponseOptionValueError]
    } else if (setOfErrors.has(InvalidRoute)) {
      validationResult.errorMessage = validationErrorText[InvalidRoute]
    } else if (setOfErrors.has(InvalidDisplayLogic)) {
      validationResult.errorMessage = validationErrorText[InvalidDisplayLogic]
    }
    return validationResult
  }

  const validateSliderNumbers = (
    entryLk: string | undefined
  ): ValidationResult => {
    const validationResult: ValidationResult = { hasError: false }

    const errors =
      (entryLk && getQuestionValidationErrors(entryLk)?.sliderErrors) ||
      emptyArr

    const setOfErrors = new Set(errors)

    if (setOfErrors.size > 0) {
      validationResult.hasError = true
    }

    const { SliderInvalidRangeError, SliderInvalidStepError } =
      SliderValidationError

    if (setOfErrors.has(SliderInvalidRangeError)) {
      validationResult.errorMessage =
        validationErrorText[SliderInvalidRangeError]
      validationResult.errorType = SliderValidationError.SliderInvalidRangeError
      return validationResult
    }
    if (setOfErrors.has(SliderInvalidStepError)) {
      validationResult.errorMessage =
        validationErrorText[SliderInvalidStepError]
      validationResult.errorType = SliderValidationError.SliderInvalidStepError
    }

    return validationResult
  }

  const validateFreeTextResponseRange = (
    entryLk: string | undefined
  ): ValidationResult => {
    const validationResult: ValidationResult = { hasError: false }

    const { ResponseRangeMinCharactersExceedError } = QuestionValidationError

    const errors =
      (entryLk && getQuestionValidationErrors(entryLk)?.errors) || emptyArr

    const setOfErrors = new Set(errors)

    if (setOfErrors.has(ResponseRangeMinCharactersExceedError)) {
      validationResult.hasError = true
      validationResult.errorMessage =
        validationErrorText[ResponseRangeMinCharactersExceedError]
      return validationResult
    }

    return validationResult
  }

  const entryHasValidationErrors = (id: string): boolean => {
    const cachedEntry = flattenedEntries.find((entry) => entry.id === id)

    if (cachedEntry?.entryType === EntryType.TextCardEntryType) {
      const cachedEntryItem = cachedEntry.entryItem
      const isNewlyCreatedEntry =
        !!newlyCreatedEntryId &&
        newlyCreatedEntryId === cachedEntryItem.textCard.textCardId
      const entryErrorData =
        validationErrors[cachedEntryItem.textCard.textCardId]

      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (!entryErrorData) {
        return false
      }

      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      return !cachedEntryItem || !isNewlyCreatedEntry
    }

    if (cachedEntry?.entryType === EntryType.QuestionEntryType) {
      const cachedEntryItem = cachedEntry.entryItem
      const isNewlyCreatedEntry =
        !!newlyCreatedEntryId &&
        newlyCreatedEntryId === cachedEntryItem.questionLk
      const entryErrorData = validationErrors[cachedEntryItem.questionLk]
      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (!entryErrorData) {
        return false
      }

      const shouldQuestionEntryShowError = () => {
        const hasNewlyCreatedResponseOption =
          // @todo Legacy eslint violation – fix this when editing
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          cachedEntryItem?.responseOptions.some(
            (responseOption) =>
              responseOption.responseOptionLk === newlyAddedResponseOptionLk
          )

        return (
          // @todo Legacy eslint violation – fix this when editing
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          (!cachedEntryItem || !isNewlyCreatedEntry) &&
          !hasNewlyCreatedResponseOption
        )
      }

      return shouldQuestionEntryShowError()
    }

    if (cachedEntry?.entryType === EntryType.MatrixEntryType) {
      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      const cachedMatrixItem = cachedEntry?.entryItem
      const entryErrorData = validationErrors[cachedMatrixItem.matrixTitleLk]

      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (!entryErrorData) {
        return false
      }

      const isNewlyCreatedEntry =
        !!newlyCreatedEntryId &&
        newlyCreatedEntryId === cachedMatrixItem.matrixTitleLk
      const hasNewlyCreatedResponseOption =
        // @todo Legacy eslint violation – fix this when editing
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        cachedMatrixItem?.responseOptions.some(
          (responseOption) =>
            responseOption.responseOptionLk === newlyAddedResponseOptionLk
        )
      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      const hasNewlyCreatedRow = cachedMatrixItem?.matrixRows.some(
        (row) => row.questionLk === newlyAddedRowLk
      )
      return (
        !isNewlyCreatedEntry &&
        !hasNewlyCreatedResponseOption &&
        !hasNewlyCreatedRow
      )
    }

    if (cachedEntry?.entryType === EntryType.ForkEntryType) {
      const forkItem = cachedEntry.entryItem as DraftForkItem
      const entryErrorData = validationErrors[forkItem.fork.forkId]

      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (!entryErrorData) {
        return false
      }

      const isNewlyCreatedEntry =
        !!newlyCreatedEntryId && newlyCreatedEntryId === forkItem.fork.forkId

      return !isNewlyCreatedEntry
    }

    return false
  }

  const hasUnclearedValidationErrors = () => {
    const entriesWithError = flattenedEntries.filter((entry) => {
      const entryId = getEntryId(entry)
      return (
        entryHasValidationErrors(entry.id) && entryId !== newlyCreatedEntryId
      )
    })

    return !isEmpty(entriesWithError)
  }

  const validateQuestionLogic = (entryId: string) => {
    return !!getQuestionValidationErrors(entryId)?.errors?.some(
      (error) =>
        error === QuestionValidationError.QuestionLogicInvalidQuestionError
    )
  }

  return {
    hasValidationErrors,
    getQuestionValidationErrors,
    getMatrixValidationErrors,
    entryHasValidationErrors,
    validateMatrixQuestionTitle,
    validateMatrixRowText,
    validateMatrixColumnText,
    validateBasicResponseOption,
    validateBasicQuestionText,
    validateSliderNumbers,
    validateFreeTextResponseRange,
    hasUnclearedValidationErrors,
    validateQuestionLogic
  }
}

export default useQuestionnaireValidation
