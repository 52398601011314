import {
  Grid,
  List,
  Text,
  TextHighlight,
  TextSize,
  TextWeight
} from '@focaldata/cin-ui-components'
import { TextWeight as TextWeightCustom } from '../../../constants/text'
import {
  DraftCustomAudience,
  DraftStandardAudience,
  FieldworkAudience,
  FieldworkAudienceType
} from '../../../data/model/audience'
import { CriterionValidationErrors } from '../../../data/model/fieldwork'
import useAudienceValidation from '../../../hooks/audience/useAudienceValidation'
import { CustomAudienceSummaryItem } from '../CustomAudienceSummaryItem'
import { StandardAudienceSummaryItem } from '../StandardAudienceSummaryItem'
import useStyles from './AudienceSummary.styles'

interface Props {
  audiences: FieldworkAudience[]
  validationErrors: CriterionValidationErrors[]
  showTitle?: boolean
  showQuotas?: boolean
  emptySubtitle?: string
  emptyDescription?: string
}

const AudienceSummary = ({
  audiences,
  validationErrors,
  showTitle = false,
  showQuotas,
  emptySubtitle = "You've added no targeting criteria or screening questions.",
  emptyDescription = 'Currently your sample will be sent to the general population. Edit targeting and screening to ensure your survey only reaches your target audience.'
}: Props) => {
  const { classes } = useStyles()
  const { surveyAudienceInvalidQuestions } = useAudienceValidation({
    validationErrors
  })

  if (audiences.length > 0) {
    return (
      <>
        {showTitle && (
          <Grid
            container
            direction="column"
            className={classes.paddingTopBottom}
          >
            <Text size={TextSize.ms} highlight={TextHighlight.Background}>
              The following audience will answer your survey.
            </Text>
          </Grid>
        )}
        <List>
          {audiences.map((audience: FieldworkAudience) => {
            if (audience.memberType === FieldworkAudienceType.Standard) {
              const standardAudience: DraftStandardAudience =
                audience.memberItem as DraftStandardAudience
              const hasError = !!surveyAudienceInvalidQuestions.find(
                (id) => id === standardAudience.criterion.code
              )
              return (
                <StandardAudienceSummaryItem
                  key={standardAudience.criterion.code}
                  audience={standardAudience}
                  hasError={hasError}
                  showQuotas={showQuotas}
                />
              )
            }
            const customAudience: DraftCustomAudience =
              audience.memberItem as DraftCustomAudience
            const hasError = !!surveyAudienceInvalidQuestions.find(
              (id) => id === customAudience.questionLk
            )
            return (
              <CustomAudienceSummaryItem
                audience={customAudience}
                key={customAudience.questionLk}
                hasError={hasError}
                showQuotas={showQuotas}
              />
            )
          })}
          {audiences.some((a) => a.isWeighted) && (
            <Grid className={classes.item}>
              <p style={{ fontWeight: TextWeightCustom.SemiBold }}>Weighting</p>
              {audiences.map((audience: FieldworkAudience) => {
                if (!audience.isWeighted) {
                  return null
                }
                let questionText = ''
                if (audience.memberType === FieldworkAudienceType.Standard) {
                  questionText = (audience.memberItem as DraftStandardAudience)
                    .criterion.name
                }
                if (audience.memberType === FieldworkAudienceType.Custom) {
                  questionText = (audience.memberItem as DraftCustomAudience)
                    .question.text
                }
                if (!questionText) {
                  return null
                }
                return <p key={audience.id}>{questionText}</p>
              })}
            </Grid>
          )}
        </List>
      </>
    )
  }
  return (
    <Grid container direction="column" className={classes.paddingTop}>
      <Text weight={TextWeight.SemiBold} size={TextSize.ms}>
        {emptySubtitle}
      </Text>
      <Text size={TextSize.ms} highlight={TextHighlight.Background}>
        {emptyDescription}
      </Text>
    </Grid>
  )
}

export default AudienceSummary
