import { useMutation } from '@apollo/client'
import { UPDATE_CUSTOM_CRITERION_OPTION_V2 } from '../../data/gql/questionnaire/mutations/criterionOptionV2'
import { draftAudienceRefetchQuery } from '../../data/gql/questionnaire/queries/audiences'
import { LoggerErrorType } from '../../data/logger'
import { captureApolloError } from '../../utils/HelperFunctions'
import { useProjectId } from '../useProjectId'
import { useSurveyId } from '../useSurveyId'

export const useUpdateCustomCriterionOptionV2 = () => {
  const projectId = useProjectId()
  const surveyId = useSurveyId()

  const [updateCustomCriterionOptionV2] = useMutation(
    UPDATE_CUSTOM_CRITERION_OPTION_V2,
    {
      context: { clientName: 'questionnaire' },
      refetchQueries: [draftAudienceRefetchQuery(projectId, surveyId)],
      onError(error) {
        captureApolloError(
          LoggerErrorType.ApolloMutation,
          'UpdateCustomCriterionOptionV2',
          error
        )
      }
    }
  )

  return updateCustomCriterionOptionV2
}
