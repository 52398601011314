import gql from 'graphql-tag'

export const UPDATE_AUDIENCE_MEMBER_V2 = gql`
  mutation updateAudienceMemberV2($input: UpdateAudienceMemberV2Input!) {
    recordAudienceChangedForIRSNotification @client(always: true)
    updateAudienceMemberV2(input: $input) {
      ...DraftAudienceMember
    }
  }
`
