import { MutationHookOptions, Reference, useMutation } from '@apollo/client'
import { useCallback } from 'react'
import { questionBeingEditedId } from '../../../../apollo/apolloClient'
import {
  CreateMatrixRowV2Mutation,
  DraftMatrixItem,
  DraftMatrixRow,
  EntryType,
  RemoveMatrixRowV2Mutation,
  UpdateMatrixRowV2Input,
  UpdateMatrixRowV2Mutation
} from '../../../../data/gql-gen/questionnaire/graphql'
import { CREATE_MATRIX_ROW } from '../../../../data/gql/questionnaire/mutations/createMatrixRow'
import { REMOVE_MATRIX_ROW } from '../../../../data/gql/questionnaire/mutations/removeMatrixRow'
import { UPDATE_MATRIX_ROW } from '../../../../data/gql/questionnaire/mutations/updateMatrixRow'
import { LoggerErrorType } from '../../../../data/logger'
import { useDraftQuestionnaireEntry } from '../../../../hooks/questionnaire/useDraftQuestionnaireEntry'
import { useProjectId } from '../../../../hooks/useProjectId'
import { responseOptionLkNewlyAdded } from '../../../../hooks/useResetNewlyCreatedEntry'
import { useSurveyId } from '../../../../hooks/useSurveyId'
import { captureApolloError } from '../../../../utils/HelperFunctions'
import { createDraftMatrixRow } from '../../Questionnaire.utils'
import { modifyMatrixRowsCache } from './MatrixQuestionRowsList.utils'

const useCreateMatrixRow = (entryId: string) => {
  const projectId = useProjectId()
  const entry = useDraftQuestionnaireEntry(entryId)
  const [createMatrixRowMutation] = useMutation(CREATE_MATRIX_ROW, {
    context: { clientName: 'questionnaire' },
    update: async (cache, { data }) => {
      if (!data?.createMatrixRowV2) {
        return
      }

      const isDraftMatrixRowType = (value: any): value is DraftMatrixRow => {
        return value?.__typename === 'DraftMatrixRow'
      }

      if (!isDraftMatrixRowType(data.createMatrixRowV2)) {
        return
      }

      const matrixRow = data.createMatrixRowV2

      if (entry?.entryType === EntryType.MatrixEntryType) {
        modifyMatrixRowsCache(cache, entry, matrixRow)
      }

      responseOptionLkNewlyAdded(matrixRow.questionLk)
      questionBeingEditedId(entry?.id)
    },
    optimisticResponse: ({ input }) => {
      return {
        __typename: 'RoutesMutations',
        createMatrixRowV2: createDraftMatrixRow(input.position)
      } as CreateMatrixRowV2Mutation
    },
    onError: (error) => {
      captureApolloError(
        LoggerErrorType.ApolloMutation,
        'createMatrixRow',
        error
      )
    }
  })

  const createMatrixRow = useCallback(
    (position: number) => {
      createMatrixRowMutation({
        variables: {
          input: {
            projectId,
            entryId,
            position,
            text: ''
          }
        }
      })
    },
    [createMatrixRowMutation, entryId, projectId]
  )

  return createMatrixRow
}

const useUpdateMatrixRow = (entryId: string, options?: MutationHookOptions) => {
  const projectId = useProjectId()
  const surveyId = useSurveyId()
  const entry = useDraftQuestionnaireEntry(entryId)

  const [updateMatrixRowMutation] = useMutation<UpdateMatrixRowV2Mutation>(
    UPDATE_MATRIX_ROW,
    {
      context: { clientName: 'questionnaire' },
      onCompleted: () => {
        questionBeingEditedId(entry?.id)
      },
      ...options
    }
  )

  const updateMatrixRow = useCallback(
    (
      input: Omit<UpdateMatrixRowV2Input, 'projectId' | 'surveyId' | 'entryId'>,
      options?: MutationHookOptions
    ) => {
      return updateMatrixRowMutation({
        variables: {
          input: {
            projectId,
            surveyId,
            entryId,
            ...input
          }
        },
        ...options
      })
    },
    [updateMatrixRowMutation, projectId, surveyId, entryId]
  )

  return updateMatrixRow
}

const useRemoveMatrixRow = (entryId: string) => {
  const projectId = useProjectId()
  const entry = useDraftQuestionnaireEntry(entryId)

  const [removeMatrixRowMutation] = useMutation<RemoveMatrixRowV2Mutation>(
    REMOVE_MATRIX_ROW,
    {
      context: { clientName: 'questionnaire' },
      onCompleted() {
        questionBeingEditedId(entry?.id)
      },
      onError(error) {
        captureApolloError(
          LoggerErrorType.ApolloMutation,
          'removeMatrixRow',
          error
        )
      }
    }
  )

  const removeMatrixRow = useCallback(
    (questionId: string) => {
      return removeMatrixRowMutation({
        variables: {
          input: {
            projectId,
            entryId,
            questionId
          }
        },
        update(cache) {
          const draftMatrixItem = entry?.entryItem

          if (
            !draftMatrixItem ||
            draftMatrixItem.__typename !== 'DraftMatrixItem'
          ) {
            throw new Error(
              'Entry item is not a DraftMatrixItem. Cache update can be only applied to DraftMatrixItem when using useRemoveMatrixRow hook.'
            )
          }

          const cacheId = cache.identify(draftMatrixItem as DraftMatrixItem)
          cache.modify<{ matrixRows: Reference[] }>({
            id: cacheId,
            fields: {
              matrixRows(existingMatrixRows = [], { readField }) {
                return existingMatrixRows.filter(
                  (matrixRowRef) =>
                    readField('questionLk', matrixRowRef) !== questionId
                )
              }
            }
          })
        }
      })
    },
    [removeMatrixRowMutation, projectId, entryId, entry?.entryItem]
  )

  return removeMatrixRow
}

export { useCreateMatrixRow, useUpdateMatrixRow, useRemoveMatrixRow }
